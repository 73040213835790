import React, { Component } from 'react';
import './App.css';
import Tabletop from 'tabletop';
import {Grid, Image, Input, Button, Loader, Divider, Icon} from 'semantic-ui-react';

class App extends Component {
  constructor() {
    super()
    this.state = {
      data: null,
      name: "",
      reward: [-1, -1, -1, -1],
      mean : [-1, -1, -1, -1]
    }
  }

  componentDidMount() {
    Tabletop.init({
      key: '1JaH5Ps5ZxLIpOwXRZXIV9Fnc1l0vdK1GN0PVSwgRhUc',
      callback: googleData => {

        var mean = [-1, -1, -1, -1]
        var dataList = []

        for(var j=0;j<4;j++){
          var data = googleData["round"+(j+1).toString()].elements
          dataList.push(data)

          for(var i=0;i<data.length;i++){
            if(data[i]["이름"] === "avg"){
              mean[j] = data[i]["리워드"]
              break
            }
          }
        }
        this.setState({data:dataList, mean:mean})
      },
    })
  }

  handleChange = (e) => {

    var s = {}
    s[e.target.name] = e.target.value
    s["reward"] = [-1, -1, -1, -1]

    this.setState(s)
  }

  searchReward = () => {
    var name = this.state.name
    var reward = [-1, -1, -1, -1]

    for(var j=0;j<4;j++){
      for(var i=0;i<this.state.data[j].length;i++){
        if(this.state.data[j][i]["이름"] === name){
          break;
          
        }
      }

      if(i < this.state.data[j].length){
        reward[j] = this.state.data[j][i]["리워드"]
      }
    }

    this.setState({reward})
  }

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }


  render() {
    return (
      <Grid columns={3}>
        <Grid.Row><br/><br/><br/></Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}></Grid.Column>
          <Grid.Column width={10}><Image src={require('./logo.png')} size="small" centered/></Grid.Column>
          <Grid.Column width={3}></Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={3}></Grid.Column>
          <Grid.Column width={10}>
            {this.state.data?
              <Grid>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <Input
                      placeholder="이름 검색"
                      fluid
                      name = "name"
                      value={this.state.name}
                      onChange={this.handleChange}>           
                    </Input>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Button onClick={this.searchReward} size="medium" fluid icon><Icon name="search" /></Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid verticalAlign="middle">
                    <Grid.Row divided>
                      <Grid.Column width={6} textAlign="center">
                        이름 
                      </Grid.Column>
                      <Grid.Column width={10} textAlign="center">
                        {`${this.state.reward.toString() !== "-1,-1,-1,-1" ? this.state.name : "No Result"}
                        `}
                      </Grid.Column>
                    </Grid.Row>
                    <Divider />
                    <Grid.Row divided>
                      <Grid.Column width={6} textAlign="center">
                      Round1 <br /> 리워드 <br /> (평균)
                      </Grid.Column>
                      <Grid.Column width={10} textAlign="center">
                      {`${this.state.reward[0].toString() !== "-1" ? this.numberWithCommas(this.state.reward[0]) + "GP" : "No Result"}`}<br />
                      {`(${this.state.mean[0] >= 0 ? this.numberWithCommas(Math.round(this.state.mean[0])) + "GP" : "No data" })`}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row divided>
                      <Grid.Column width={6} textAlign="center">
                      Round2 <br /> 리워드 <br /> (평균)
                      </Grid.Column>
                      <Grid.Column width={10} textAlign="center">
                      {`${this.state.reward[1].toString() !== "-1" ? this.numberWithCommas(this.state.reward[1]) + "GP" : "No Result"}`}<br />
                      {`(${this.state.mean[1] >= 0 ? this.numberWithCommas(Math.round(this.state.mean[1])) + "GP" : "No data" })`}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row divided>
                      <Grid.Column width={6} textAlign="center">
                      Round3 <br /> 리워드 <br /> (평균) 
                      </Grid.Column>
                      <Grid.Column width={10} textAlign="center">
                      {`${this.state.reward[2].toString() !== "-1" ? this.numberWithCommas(this.state.reward[2]) + "GP" : "No Result"}`}<br />
                      {`(${this.state.mean[2] >= 0 ? this.numberWithCommas(Math.round(this.state.mean[2])) + "GP" : "No data" })`}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row divided>
                      <Grid.Column width={6} textAlign="center">
                      Round4 <br /> 리워드 <br /> (평균)
                      </Grid.Column>
                      <Grid.Column width={10} textAlign="center">
                      {`${this.state.reward[3].toString() !== "-1" ? this.numberWithCommas(this.state.reward[3]) + "GP" : "No Result"}`}<br />
                      {`(${this.state.mean[3] >= 0 ? this.numberWithCommas(Math.round(this.state.mean[3])) + "GP" : "No data" })`}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Row>
              </Grid>
                            
          :
            <Grid textAlign="center">
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <Loader active size="large">Loading...</Loader>
            </Grid>
          }
          </Grid.Column>
          <Grid.Column width={3}></Grid.Column>
         </Grid.Row>
      </Grid>
    );
  }
}

export default App;